import React from 'react'
function rootView() {
  return (
    <div className="v-layout">
      <div className="jumbotron jumbotron-fluid bg-primary text-white">
        <div className="container">
          <h1 className="display-4">Experto crede</h1>
          <p className="lead">Вместо тысячи слов</p>
        </div>
      </div>
    </div>
  )
}
export default rootView
